import './manageproject.css';
import {useEffect, useState } from 'react';
import { _projectapiUrl } from '../../apiUrl';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';

function ManageProject(){

    const navigate = useNavigate();
    const [selectfile,setFile] = useState();
    const [title,setTitle] = useState();
    const [description,setDescription] = useState();
    const [error,setError] = useState(false);
    const [success,setSuccess] = useState();
    const [projectDetails,setprojectDetails] = useState();
    const fileInputRef = useRef(null);
    
    const handleChange = (event)=>{
        const file = event.target.files[0];
        if (file && (file.type === 'image/jpeg'||file.type === 'image/png')) {
            setFile(file);
        }
        else
        {
            alert('Please select a valid JPG or PNG image.');
        }
    }
    const hendleSubmit = (event)=>{
        event.preventDefault();
        var formData = new FormData();
        formData.append('title',title);
        formData.append('description',description);
        formData.append('projectimg',selectfile);
        const config = {
            headers:{
                'content-type':'multipart/form-data'
            }
        };
        if (!title || !description || !selectfile) {
            alert("Please provide title, description, and select an image.");
            
        }
        else
        {
            axios.post(_projectapiUrl+"save",formData,config).then((result)=>{
                setSuccess("Project Added Successfully");
                setTitle("");
                setDescription("");
                fileInputRef.current.value = ''; 
                setTimeout(() => {
                    setSuccess(); 
                  }, 3000);
            }).catch((error)=>{
                setError("Failed");
                setTimeout(()=>{
                    setError();
                },3000);
            });
        }
    }
    
    // fetch data
    useEffect(()=>{
        axios.get(_projectapiUrl+"fetch").then((result)=>{
            setprojectDetails(result.data);
        }).catch((error)=>{
            console.log(error);
        });
    },[projectDetails]);

    const hendleDelete = (_id)=>{
            let deleteDetails = {"data":{"_id":_id}};
            if (window.confirm("Warning: Do you really want to delete the project?")){
                axios.delete(_projectapiUrl + "delete", deleteDetails).then((response)=>{ 
                    navigate("/admin");
                }).catch((error)=>{
                    console.log(error.data);
                });
            }
            else
            {
                alert("project canceled delete action.");
            }
    }

    return(
    <>
        <div className="manage_project">
            <div className="d-flex justify-content-between align-items-center">
            <h2 className="heading">Manage Projects</h2>
            <button className='button mb-2' type='button' data-bs-toggle="modal" data-bs-target="#exampleModal">Add projects</button>
            </div>
            <div className="table">
             <table className="table table-striped table-bordered  mt-0">
                            <thead className=''>
                                <tr>
                                    <th scope="col">RegID</th>
                                    <th scope="col">Title</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Image</th>
                                    <th scope="col">Info</th>
                                    <th scope="col">Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                     projectDetails && projectDetails.map((row)=>(
                                        <tr  key={row._id}>
                                            <td><p>{row._id}</p></td>
                                            <td><p>{row.title}</p></td>
                                            <td><p>{row.description}</p></td>
                                            <td><img src={`https://indoresolarenergy.com/assets/uploads/${row.projectimg}`} style={{ "width": "80px" }} alt='' /></td>
                                            <td><p>{row.info}</p></td>
                                            <td><button className='btn btn-danger' onClick={()=>{hendleDelete(row._id)}}>Delete</button></td>
                                            
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
            </div>
        </div>

        {/* modal project */}
        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Add Project</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <form>
                    <div>
                        <input type="text" placeholder="Project title" value={title} onChange={e=>{setTitle(e.target.value)}}/>
                        {error && title.length <= 0?<span className='text-danger'>Title is required</span>:""}
                    </div>
                    <div>
                    <textarea type="text" className="message-box" placeholder="Project Description" value={description} onChange={e=>{setDescription(e.target.value)}}></textarea>
                    {error && description.length <= 0?<span className='text-danger'>Description is required</span>:""}
                    </div>
                    <div>
                        <input type="file" ref={fileInputRef} placeholder="Choose file" accept=".jpg, .jpeg, .png" onChange={handleChange} />
                        {error && <span className='text-danger'>Please select a valid JPG or PNG image</span>}
                    </div>
                    <button type="button" className="button1 mb-2 d-block" onClick={hendleSubmit}>Submit</button>
                    {/* <b><span className='text-danger'>{error}</span></b> */}
                    <b><span className='text-success'>{success}</span></b>
                </form>
                </div>
                </div>
            </div>
        </div>
    </>
    );
}
export default ManageProject;