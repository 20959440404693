import { useEffect, useState } from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import Popup from '../popup/popup';
function Footer() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    useEffect(() => {
        const headerContent = ()=>{
            const token = localStorage.getItem("token");
            const role = localStorage.getItem("role");
        // Check if token exists and role is "admin"
        if (token !== undefined && role === "admin") {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
        }

        headerContent();
        const intervalId = setInterval(() => {
          headerContent();
        }, 1000);
    
        return () => clearInterval(intervalId);
      }, []); 

    return(
        <>
        {isLoggedIn?(<AdminUser/>):(<HomeUser/>)}
        </>
        );

        function AdminUser(){
            return(
                <>
                <footer className="footer_section">
                    <div className="container">
                        <p>
                        &copy; <span id="displayDateYear"></span> All Rights Reserved By
                        <Link to="/"> Indore Solar Energy Solution Pvt Ltd</Link>
                        </p>
                    </div>
                </footer>
                </>
            );
        }

        function HomeUser(){
            return(
                <>
                <Popup/>
                <section className="info_section pt-5">
                    <div className="container-fluid">
                        <div className="row g-0">
                        <div className="col-lg-12 mx-auto">
                            <div className="footer_items">
                            <div className="row g-0">
                                <div className="col-sm-12 col-md-6 col-lg-4 mb-5">
                                <h4>CONTACT US</h4>
                                <p>Indore Solar Energy Solution Pvt Ltd</p>
                                <span>Address: 64, Shri Managal Nagar<br/> Bicholi Hapsi Road, Indore</span>
                                <br/>
                                <span>Phone Number: <Link to="tel:8982304701" target='_blank'> 8982304701</Link></span>
                                <br/>
                                <span>Secondary Phone Number: <Link to="tel:8982900472" target='_blank'> 8982900472</Link></span>
                                <br/>
                                <span>Email:<Link to="mailto:info@indoresolar.in">info@indoresolar.in</Link></span>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-3 mb-5">
                                <h4>AREA OF SERVICE</h4>
                                <ul>
                                    <li><Link to="/"><i className="fa fa-caret-right" aria-hidden="true"></i> Residential Rooftop Solar System</Link></li>
                                    <li><Link to="/"><i className="fa fa-caret-right" aria-hidden="true"></i> Commercial Solar System</Link></li>
                                    <li><Link to="/"><i className="fa fa-caret-right" aria-hidden="true"></i> Residential Society Solar System</Link></li>
                                </ul>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-3 mb-5">
                                <h4>TYPES OF SYSTEMS</h4>
                                <ul>
                                    <li><Link to="/"><i className="fa fa-caret-right" aria-hidden="true"></i> On-Grid Solar System</Link></li>
                                    <li><Link to="/"><i className="fa fa-caret-right" aria-hidden="true"></i> Off Grid Solar System</Link></li>
                                    <li><Link to="/"><i className="fa fa-caret-right" aria-hidden="true"></i> Hybrid Solar System</Link></li>
                                </ul>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-2 mb-5">
                                <h4>SUBSIDY SCHEME</h4>
                                <ul>
                                <li><Link to = "https://solarrooftop.gov.in/" target='_blank'><i className="fa fa-caret-right" aria-hidden="true"></i> National Portal for Solar Subsidy</Link></li>
                                </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>
                <section className="info_section py-2">
                    
                    <div className="social-box">
                        <h4 className='mb-1'>
                        Follow Us
                        </h4>
                        <div className="box mb-2">
                        <Link to="https://facebook.com" target='_blank'>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                        </Link>
                        <Link to="/" target='_blank'>
                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                        </Link>
                        <Link to="https://instagram.com" target='_blank'>
                            <i className="fa fa-instagram" aria-hidden="true"></i>
                        </Link>
                        <Link to="https://twitter.com" target='_blank'>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                        </Link>
                        <Link to="https://youtube.com" target='_blank'>
                            <i className="fa fa-youtube" aria-hidden="true"></i>
                        </Link>
                        </div>
                    </div>
                </section>
                <footer className="footer_section footer-border">
                    <div className="container">
                        <p>
                        &copy; <span id="displayDateYear"></span> All Rights Reserved By
                        <Link to="/"> Indore Solar Energy Solution Pvt Ltd</Link>
                        </p>
                    </div>
                </footer>
                </>
            );
        }
}
export default Footer;