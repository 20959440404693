import './Project.css';
import { useEffect, useState } from 'react';
import {Link } from 'react-router-dom';
import axios from 'axios';
import { _projectapiUrl } from '../../apiUrl';
function Project() {

  const [projectDetails,setprojectDetails] = useState();
  // fetch data
  useEffect(()=>{
    axios.get(_projectapiUrl+"fetch").then((result)=>{
        setprojectDetails(result.data);
        // console.log(result.data);
    }).catch((error)=>{
        console.log(error);
    });
},[projectDetails]);

    return(
        <>
        <section className='project_gallery py-5'>
          <div className="container-fluid">
            <div className="gallery_heading">
              <h2>Projects Gallery</h2>
              <p>Indore Solar Energy Solution Pvt Ltd</p>
            </div>
              <div className="row g-4 mt-4">
                {
                  projectDetails && projectDetails.map((row)=>(
                    <div className="col-lg-3 col-md-12" key={row._id}>
                          <div className="card">
                            <img src={`./assets/uploads/${row.projectimg}`} alt=""/>
                              <div className="overlay">
                                <h1 className="text-h1">{row.title}</h1>
                                <p className="text-p">{row.description}</p>
                                <Link className="link-a" to={`/showproject/${row._id}`}> View More</Link>
                              </div>
                          </div>
                    </div>
                    
                  ))
                }
              </div>
	          </div>
        </section>
        </>
        );
}
export default Project;