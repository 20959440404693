import './Header.css';
import $ from 'jquery';
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Auth from '../Auth/auth';
import { useLocation } from 'react-router-dom';
function Header(){
    const location = useLocation();

    const [activeNavItem, setActiveNavItem] = useState(1);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const handleNavItemClick = (id) => {
        setActiveNavItem(id);
      };

    useEffect(() => {
        const hideSpinner = () => {
          setTimeout(() => {
            const spinnerElement = document.getElementById('spinner');
            if (spinnerElement) {
              spinnerElement.classList.remove('show');
            }
          }, 1);
        };
    
        hideSpinner();
    },[]);

    useEffect(() => {
        const handleScroll = () => {
          const scrollTop = window.scrollY;
          const navBarElement = document.querySelector('.nav-bar');
    
          if (navBarElement) {
            if (scrollTop > 500) {
              navBarElement.classList.add('sticky-top');
            } else {
              navBarElement.classList.remove('sticky-top');
            }
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    
      useEffect(() => {
        const headerContent = ()=>{
            const token = localStorage.getItem("token");
            const role = localStorage.getItem("role");
        // Check if token exists and role is "admin"
        if (token !== undefined && role === "admin") {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
        }

        headerContent();
        const intervalId = setInterval(() => {
          headerContent();
        }, 1000);
    
        return () => clearInterval(intervalId);
      }, []); 
      
      const handleLogout = () => {
        localStorage.clear();
        setIsLoggedIn(false);
      };
      
    // useEffect(()=>{
    //     if (localStorage.getItem("token") !== undefined && localStorage.getItem("role") === "admin") {
    //         setHeaderContent(
    //             <>
                
    //             </>
    //         );
    //     }
    //     else
    //     {
    //         setHeaderContent(
    //             <>
                
    //             </>
    //         )
    //     }
    // },[headercontent]);
    return(

        <>
        <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div className="spinner-border text-primary spin" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
        {isLoggedIn?<AdminUser/>:<HomeUser/>}
        <Auth/>
        </>
    );
    function AdminUser(){
        return(
            <>
            <header className="header_section">
                    <div className="header_bottom hero_area">
                        <div className="container-fluid">
                            <nav className="navbar navbar-expand-lg custom_nav-container p-0">
                            <Link className="navbar-brand" to="/">
                                <img src='./assets/images/logo.png'alt = "logo..."/>
                            </Link>
                
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <img src="./assets/images/menu.png" alt=""/>
                            </button>
                
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav ">
                                <li className={`nav-item ${activeNavItem === 0 ? 'active' : ''}`} onClick={() => handleNavItemClick(0)}>
                                    <Link className="nav-link" to="/admin">AdminHome</Link>
                                </li>
                                <li className={`nav-item dropdown ${activeNavItem ===1 ? 'active' : ''}`} onClick={() => handleNavItemClick(1)}>
                                    <Link className="nav-link dropdown-toggle" to="/admin" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa fa-user" aria-hidden="true"></i>&nbsp;{localStorage.getItem("name")}
                                    </Link>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li><Link className="dropdown-item " to="/edit-profile">Edit profile</Link></li>
                                        <li><Link className="dropdown-item " to="/logout" onClick={handleLogout}>LogOut</Link></li>
                                        
                                    </ul>
                                    </li>
                                <li className="nav-item">
                                    {/* <Link className="nav-link" to="/logout">LogOut</Link> */}
                                </li>
                                </ul>
                            </div>
                            </nav>
                        </div>
                    </div>
                </header>
            </>
        );
    }
    function HomeUser(){
        return(
            <>
            <header className="header_section">
                    <div className="header_top">
                        <div className="container-fluid">
                            <div className="contact_nav">
                                <div className="top_header">
                                    <Link to="tel:+918982304701">
                                        <i className="fa fa-phone" aria-hidden="true"></i>
                                        <span>
                                        &nbsp;Call: +918982304701&nbsp;&nbsp;
                                        </span>
                                    </Link>
                                    <Link to="mailto:info@indoresolar.in">
                                        <i className="fa fa-envelope" aria-hidden="true"></i>
                                        <span>
                                        &nbsp;Email: info@indoresolar.in
                                        </span>
                                    </Link>
                                </div>
                                <div className="social-box">
                                    <div className="box">
                                        <Link to="https://facebook.com" target='_blank'>
                                            <i className="fa fa-facebook" aria-hidden="true"></i>
                                        </Link>
                                        <Link to="/" target='_blank'>
                                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                                        </Link>
                                        <Link to="https://instagram.com" target='_blank'>
                                            <i className="fa fa-instagram" aria-hidden="true"></i>
                                        </Link>
                                        <Link to="https://twitter.com" target='_blank'>
                                            <i className="fa fa-twitter" aria-hidden="true"></i>
                                        </Link>
                                        <Link to="https://youtube.com" target='_blank'>
                                            <i className="fa fa-youtube" aria-hidden="true"></i>
                                        </Link>
                                    </div>
                                </div>    
                            </div>
                        </div>
                    </div>
                    <div className="header_bottom hero_area nav-bar">
                        <div className="container-fluid">
                            <nav className="navbar navbar-expand-lg custom_nav-container p-0">
                            <Link className="navbar-brand" to="/">
                                <img src='./assets/images/logo.png'alt = "logo..."/>
                            </Link>
                
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <img src="./assets/images/menu.png" alt=""/>
                            </button>
                
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                <li className={`nav-item ${location.pathname === "/" ? 'active' : ''}`} >
                                    <Link className="nav-link" to="/" exact>Home</Link>
                                </li>
                                <li className={`nav-item ${location.pathname === "/about" ? 'active' : ''}`}>
                                    <Link className="nav-link" to="/about"> About Us</Link>
                                </li>
                                <li className={`nav-item ${location.pathname === "/service" ? 'active' : ''}`}>
                                    <Link className="nav-link" to="/service">Services</Link>
                                </li>
                                <li className={`nav-item ${location.pathname === "/projects" ? 'active' : ''}`}>
                                    <Link className="nav-link" to="/projects">Projects</Link>
                                </li>
                                <li className={`nav-item ${location.pathname === "/Howwework" ? 'active' : ''}`}>
                                    <Link className="nav-link" to="/Howwework">How We Work</Link>
                                </li>
                                <li className={`nav-item ${location.pathname === "/contact" ? 'active' : ''}`}>
                                    <Link className="nav-link" to="/contact">Contact Us</Link>
                                </li>
                                </ul>
                            </div>
                            </nav>
                        </div>
                    </div>
                </header>
            </>
        );
    }
}

export default Header;