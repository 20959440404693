import './Service.css';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
function Service() {
  
    useEffect(()=>{
        window.$(".owl-carousel").owlCarousel({
            loop: true,
            margin: 10,
            nav: true,
            items: 4,
            dots: true,
            navText: [
                '<i className="fa fa-long-arrow-left" aria-hidden="true"></i>',
                '<i className="fa fa-long-arrow-right" aria-hidden="true"></i>'
            ],
            autoplay: true,
            autoplayHoverPause: false,
            responsive: {
                0: {
                    items: 2
                },
                586: {
                    items: 3
                },
                768: {
                    items: 4
                },
                1000: {
                    items: 7
                }
            }
        });
    },[]);
    const handleButtonClick = (e) => {
      const newTab = window.open('../assets/images/indore-solar-brochure.pdf', '_blank');
  
      if (newTab) {
          
          newTab.onload = () => {
              
              newTab.document.title = 'indore-solar-brochure.pdf';
              window.document.title='indore-solar-brochure.pdf';
          };
      } else {
        e.preventDefault();
          alert("Please allow popups for this website");
      }
    }
    return(
        <>
        {/* service section */}
        <section className="service_content">
        <section className="about_section py-5 product_section">
        <div className="overlay"></div>
            <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12">
                <div className="detail-box">
                    <h2>
                    Product Associate Partners
                    </h2>
                    <p className='service_para text-light'>Introducing Mohar Singh, a passionate renewable energy expert with over 8 years of experience in sales and solar installation. Their expertise has been instrumental in the Laisoning and installation of solar panels for over 1000 families, helping countless homeowners and businesses transition to clean energy and reduce their carbon footprint. Mohar Singh's commitment to exceptional customer service and attention to detail has earned them a reputation for delivering high-quality, customized solutions that meet the unique needs of each client. With a deep understanding of the renewable energy landscape and a dedication to driving change towards a more sustainable future, Mohar Singh is a valuable addition to our team of renewable energy specialists.</p>
                    <Link to="/projects">See Projects</Link>
                </div>
                </div>
                {/* <div className="col-lg-4 col-md-12">
                <div className="profile_pic">
                    <img src="./assets/images/about-img.jpg" alt=""/>
                </div>
                </div> */}
            </div>
            </div>
        </section>
        
        <section className="service_section service_box py-5">
            <div className="container ">
                <div className="heading_container heading_center mb-5">
                    <h2>OUR SERVICES</h2>
                </div>
                <div className="row gy-5">
                    <div className="col-sm-12 col-md-12 col-lg-6 mb-5">
                        <div className="box">
                            <div className="img-box">
                            <img src="./assets/images/s1.png" alt="" />
                            </div>
                            <div className="detail-box">
                            <h5>GROUND MOUTED SOLAR INSTALLATION</h5>
                            <p>Ground mounted solar installation involves installing solar panels on the ground, typically in an open area such as a backyard or field. It offers increased efficiency, easier maintenance, and greater flexibility in panel placement compared to rooftop installation. It's ideal for those with higher energy consumption needs and is also popular for community solar projects. </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 mb-5">
                        <div className="box ">
                            <div className="img-box">
                            <img src="./assets/images/s2.png" alt="" />
                            </div>
                            <div className="detail-box">
                            <h5>ROOFTOP SOLAR INSTALLATION </h5>
                            <p>Rooftop solar installation involves mounting solar panels on a building's roof to generate electricity. It's a popular and cost-effective option for those with suitable roof space and provides numerous advantages, such as maximizing space utilization and potentially even selling excess energy back to the grid. Rooftop solar installation is an excellent investment for those looking to save money and reduce their environmental impact.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 mb-5">
                        <div className="box ">
                            <div className="img-box">
                            <img src="./assets/images/s2.png" alt="" />
                            </div>
                            <div className="detail-box">
                            <h5>SOLAR CLEANING AND MAINTENANCE </h5>
                            <p>Solar cleaning and maintenance are crucial for the optimal performance of your solar panels. Regular cleaning prevents buildup and maintains efficiency, resulting in increased energy production and cost savings. Activities include removing debris, cleaning with water and a brush, and checking for damage. Schedule regular maintenance visits with a professional to extend the lifespan of your solar panels and maximize energy production potential.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 mb-5">
                        <div className="box ">
                            <div className="img-box">
                            <img src="./assets/images/s3.png" alt="" />
                            </div>
                            <div className="detail-box">
                            <h5>COMMERCIAL SOLAR INSTALLATION</h5>
                            <p>Commercial solar installation involves installing solar panels on commercial properties to reduce electricity bills, increase energy independence, and reduce carbon emissions. It's a wise investment that provides significant cost savings over time, enhances sustainability credentials, and contributes to a cleaner future. The installation process includes assessing energy needs, designing a customized solar solution, and ensuring regulatory compliance.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btn-box m-0">
                <Link target="_blank" rel="noopener noreferrer"  onClick={handleButtonClick}>
                          View More
                          </Link>
                </div>
            </div>
        </section>
        <section className="service_work py-5">
            <div className="overlay"></div>
            <div className="container">
                <div className="heading_container heading_center mb-2">
                    <h2 className='text-white'>OUR WORKS</h2>
                </div>
                <div className="row">
                    <div className="col-md-12 mx-auto">
                      <nav>
                        <div className="nav nav-tabs mb-5" id="nav-tab" role="tablist">
                          <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">RESIDENTIAL</button>
                          <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">COMMERCIAL</button>
                          <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">INDUSTRIAL</button>
                        </div>
                      </nav>
                      <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                          <div className='content_residental text-center'>
                            <h3>GROUND MOUTED SOLAR INSTALLATION</h3>
                            <p className='founder_para'>Ground Mounted Solar Installation Involves Installing Solar Panels On The Ground, Typically In An Open Area Such As A Backyard Or Field. It Offers Increased Efficiency, Easier Maintenance, And Greater Flexibility In Panel Placement Compared To Rooftop Installation. It's Ideal For Those With Higher Energy Consumption Needs And Is Also Popular For Community Solar Projects.</p>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                          <div className='content_residental text-center'>
                            <h3>COMMERCIAL SOLAR INSTALLATION</h3>
                            <p className='founder_para'>Commercial Solar Installation Involves Installing Solar Panels On Commercial Properties To Reduce Electricity Bills, Increase Energy Independence, And Reduce Carbon Emissions. It's A Wise Investment That Provides Significant Cost Savings Over Time, Enhances Sustainability Credentials, And Contributes To A Cleaner Future. The Installation Process Includes Assessing Energy Needs, Designing A Customized Solar Solution, And Ensuring Regulatory Compliance.</p>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                          <div className='content_residental text-center'>
                            <h3>SOLAR CLEANING AND MAINTENANCE</h3>
                            <p className='founder_para'>Solar Cleaning And Maintenance Are Crucial For The Optimal Performance Of Your Solar Panels. Regular Cleaning Prevents Buildup And Maintains Efficiency, Resulting In Increased Energy Production And Cost Savings. Activities Include Removing Debris, Cleaning With Water And A Brush, And Checking For Damage. Schedule Regular Maintenance Visits With A Professional To Extend The Lifespan Of Your Solar Panels And Maximize Energy Production Potential.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="professional_section pt-5 text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">
              <div className="detail-box">
                <h2>We collaborate with top-notch Brands in the industry to deliver exceptional renewable energy solutions</h2>
                <p className='p-0'>We are the best choice for Solar EPC website needs. Our customized solutions are tailored to the specific needs of solar EPC companies. We have a deep understanding of the industry and focus on lead generation and customer engagement. Our expertise in user experience and responsive design ensures that your website is accessible on any device. We offer ongoing support and maintenance to keep your website up-to-date with the latest industry trends and technologies. Choose us for the best Solar EPC website solutions.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="client_section pb-0 " style={{"background": "#d1e3ff"}}>
      <div className="container-fluid">
        <div className="carousel-wrap layout_padding2-top ">
          <div className="owl-carousel">
            <div className="item">
              <div className="box service_client">
                <div className="client_id">
                    <img src="./assets/images/client-1.png" alt=""/>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="box service_client">
                <div className="client_id">
                        <img src="./assets/images/client-2.png" alt=""/>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="box service_client">
                <div className="client_id">
                        <img src="./assets/images/client-3.png" alt=""/>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="box service_client">
                <div className="client_id">
                        <img src="./assets/images/client-4.png" alt=""/>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="box service_client">
                <div className="client_id">
                        <img src="./assets/images/client-5.png" alt=""/>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="box service_client">
                <div className="client_id">
                        <img src="./assets/images/client-6.png" alt=""/>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="box service_client">
                <div className="client_id">
                        <img src="./assets/images/client-7.png" alt=""/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </section>
        </>
        );
}
export default Service;