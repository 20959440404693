import './App.css';
import Header from './component/Header/Header';
import Footer from './component/Footer/Footer';
import Home from './component/Home/Home';
import About from './component/About/About';
import Service from './component/Services/Service';
import Contact from './component/Contact/Contact';
import Project from './component/projects/Project';
import { Route,Routes } from 'react-router-dom';
import Login from './component/Login/login';
import Logout from './component/Logout/logout';
import HowweWork from './component/Howwework/Howwork';
import EditProfile from './component/Edit-profile/Editprofile';
import Admin from './component/Admin/Admin';
import ShowProject from './component/Showprojects/showproject';
function App() {
  return (
    <div className="">
      <Header/>
      <Routes>

        <Route path="/login" element={<Login/>}></Route>
        <Route path="/logout" element={<Logout/>}></Route>
        <Route path="/" element={<Home/>}></Route>
        <Route path="/about" element={<About/>}></Route>
        <Route path="/contact" element={<Contact/>}></Route>
        <Route path="/service" element={<Service/>}></Route>
        <Route path="/showproject/:Id" element={<ShowProject/>}></Route>
        <Route path="/projects" element={<Project/>}></Route>
        <Route path="/Howwework" element={<HowweWork/>}></Route>
        <Route path="/edit-profile" element={<EditProfile/>}></Route>
        <Route path="/admin" element={<Admin/>}></Route>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
