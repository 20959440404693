
import React from 'react';
import { useState } from 'react';
import axios from 'axios';
import { _userapiUrl } from '../../../../apiUrl';
import './contact.css'; 

const ContactPop = ({ contactOpen}) => {
  

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error,setError] = useState(false);
  const [success,setSuccess] = useState();
  if (!contactOpen) return null;
  const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

  const handleSubmit = (e)=>{
      e.preventDefault();
      if (name.length==0 || mobile.length <= 9|| true == !isValidEmail(email) ||message == 0 ) {
          setError(true);
      }
     else
     {
      const userDetails = {"name":name,"mobile":mobile,"email":email,"comment":message};
      // console.log(userDetails)
      axios.post(_userapiUrl+"save",userDetails).then((result)=>{
          setName("");
          setEmail("");
          setMessage("");
          setMobile("");
          setSuccess("Submitted Successfully");
          setError(false);
          setTimeout(()=>{
              setSuccess("");
          },3000);
      }).catch((error)=>{

      })
     }
      
  }
  

  return (
    <>
    <div className="contact_content">
      <div className="popup-content">
      <div className="m-0 text-center">
                    <h2>Get Quote Now !!</h2>
                    <p>Please Share your Details our team will connect soon</p>
                </div>
      <form className='mb-3'>
          <div>
          <input type="text" placeholder="Full Name" value={name} onChange={e=>{setName(e.target.value)}}/>
          {error && name.length <= 0?<span className='text-danger'>Name is required</span>:""}
          </div>
          <div>
          <input type="number" placeholder="Phone Number" value={mobile} onChange={e=>{setMobile(e.target.value)}} />
          {error && mobile.length <= 9?<span className='text-danger'>Mobile number is required</span>:""}
          </div>
          <div>
          <input type="email" placeholder="Email Address" value={email} onChange={e=>{setEmail(e.target.value)}} required/>
          {error && !isValidEmail(email)?<span className='text-danger'>Email is required</span>:""}
          </div>
          <div>
          <textarea type="text" className="message-box" placeholder="Comment" value={message} onChange={e=>{setMessage(e.target.value)}}></textarea>
          {error && message.length <= 0?<span className='text-danger'>Comment is required</span>:""}
          </div>
          <div className="d-flex mb-1">
          <a className='send_btn' type='button'onClick={handleSubmit}>SEND</a>
          </div>
          <b><span className='text-success'>{success}</span></b>
      </form>
      </div>
    </div>
    </>
      
    
  );
};

export default ContactPop;
