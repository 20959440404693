import './DashboardContent.css';
import CountUp from 'react-countup';
function DashboardContent() {
    return(
        <>
        <section className="dashboard_section py-0">
            <div className="container">
                <h2 className="heading">DashBoard</h2>
                <div className="row w-100">
                <div className="col-md-10">
                <div className="row w-100">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                        <div className="box">
                            <div className="dashboard_count">
                                <CountUp start={0} end={700} delay={5} duration={5} enableScrollSpy={true} decimals={0}>
                                    {({countUpRef})=>(
                                    <h1><span ref={countUpRef}/>+</h1>
                                    )}
                                </CountUp>
                                <h4>Happy Clients</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                        <div className="box">
                            <div className="dashboard_count">
                                <CountUp start={0} end={156} delay={5} duration={5} enableScrollSpy={true} decimals={0}>
                                    {({countUpRef})=>(
                                    <h1><span ref={countUpRef}/>+</h1>
                                    )}
                                </CountUp>
                                <h4>KW installed</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                        <div className="box">
                            <div className="dashboard_count">
                                <CountUp start={0} end={4.5} delay={5} duration={5} enableScrollSpy={true} decimals={1}>
                                    {({countUpRef})=>(
                                    <h1><span ref={countUpRef}/></h1>
                                    )}
                                </CountUp>
                                <h4>Google Rating</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                        <div className="box">
                            <div className="dashboard_count">
                                <CountUp start={0} end={315} delay={5} duration={5} enableScrollSpy={true} decimals={0}>
                                    {({countUpRef})=>(
                                    <h1><span ref={countUpRef}/>+</h1>
                                    )}
                                </CountUp>
                                <h4>OUR PROJECTS</h4>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </div>
            </div>
        </section>
        </>
        );
}
export default DashboardContent;