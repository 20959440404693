import React, { useState } from 'react';
import './popup.css'
import MainPopup from './mainpopup/mainpopup';

function Popup(){
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isicon, setIsicon] = useState(<i class="fa fa-comments-o" aria-hidden="true"></i>);
  
    const handlePopupOpen = () => {
      setIsPopupOpen(true);
      setIsicon(<i class="fa fa-times" aria-hidden="true"></i>);
      if (isPopupOpen === true) {
        setIsPopupOpen(false);
        setIsicon(<i class="fa fa-comments-o" aria-hidden="true"></i>)
      }
    };

    return(
        <>
        <div className="popup">
          <div className="popup_btn">
          <MainPopup isOpen={isPopupOpen}/>
          <button className='message_btn' onClick={handlePopupOpen}>{isicon}</button>
          </div>
          </div>
        </>
    );
}

export default Popup;