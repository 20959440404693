import './About.css';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import React from 'react';
function About() {

  const handleButtonClick = (e) => {
    const newTab = window.open('../assets/images/indore-solar-brochure.pdf', '_blank');

    if (newTab) {
        
        newTab.onload = () => {
            
            newTab.document.title = 'indore-solar-brochure.pdf';
            window.document.title='indore-solar-brochure.pdf';
        };
    } else {
      e.preventDefault();
        alert("Please allow popups for this website");
    }
  }

    return(
        <>
        <section className="professional_section bg-white py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="detail-box">
                  <h2>WHAT WE DO ?</h2>
                  <p className='p-0'>We are a solar engineering, procurement, and construction (EPC) company located in Indore, committed to providing sustainable energy solutions to our community. With years of experience in the solar energy industry, we specialize in designing, building, and maintaining solar energy systems for residential, commercial, and industrial properties in and around Indore.</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="about_pic">
                  <img src="./assets/images/what.jpg" alt=""/>
                </div>
              </div>
              <div className="col-md-6">
                <div className="about_pic">
                  <img src="./assets/images/goal.jpg" alt=""/>
                </div>
              </div>
              <div className="col-md-6">
                <div className="detail-box">
                  <h2>OUR GOAL</h2>
                  <p className='p-0'>Our goal is to make this technology accessible to everyone in our community in Indore. We are committed to promoting the use of solar energy and making it a viable option for all residents in the region. We invite you to join us on our mission to create a brighter and more sustainable future for Indore.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="counter_section">
          <div className="overlay"></div>
          <div className="container">
            <div className="counter_content">
                <div className="project_count">
                <CountUp start={0} end={700} delay={5} duration={5} enableScrollSpy={true} decimals={0}>
                {({countUpRef})=>(
                  <h1><span ref={countUpRef}/>+</h1>
                )}
                </CountUp>
                  <h4>Happy Clients</h4>
              </div>
                <div className="project_count">
                <CountUp start={0} end={156} delay={5} duration={5} enableScrollSpy={true} decimals={0}>
                {({countUpRef})=>(
                  <h1><span ref={countUpRef}/>+</h1>
                )}
                </CountUp>
                  <h4>KW installed</h4>
                </div>
                <div className="project_count">
                <CountUp start={0} end={4.5} delay={5} duration={5} enableScrollSpy={true} decimals={1}>
                {({countUpRef})=>(
                  <h1><span ref={countUpRef}/></h1>
                )}
                </CountUp>
                  <h4>Google Rating</h4>
                </div>
                <div className="project_count">
                <CountUp start={0} end={315} delay={5} duration={5} enableScrollSpy={true} decimals={0}>
                {({countUpRef})=>(
                  <h1><span ref={countUpRef}/>+</h1>
                )}
                </CountUp>
                  <h4>OUR PROJECTS</h4>
                </div>
            </div>
          </div>
        </section>
        <section className="about_section py-3 my-5 founder_profile">
            <div className="container">
              <div className="heading text-center">
              <h2>Meet Our Founding Members</h2>
              </div>
              <div className="row justify-content-center">
                  <div className="col-lg-10">
                    <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="box_profile">
                      <div className="profile">
                            <img src="./assets/images/mohar.png" alt=""/>
                      </div>
                      <div className="detail-box text-center mt-4">
                          <h5><b style={{"color":"#fcc72d"}}>Mohar Singh Gurjar</b></h5>
                          <h6><b>Founder Promoter</b></h6>
                          <p className='founder-p'>Introducing Mohar Singh, a passionate renewable energy expert with over 8 years of experience in sales and solar installation. Their expertise has been instrumental in the Laisoning and installation of solar panels for over 1000 families.</p>
                      </div>
                      </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                      <div className="box_profile">
                      <div className="profile">
                            <img src="./assets/images/manish.png" alt=""/>
                      </div>
                      <div className="detail-box text-center mt-4">
                          <h5><b style={{"color":"#fcc72d"}}>Manish Patel</b></h5>
                          <h6><b>Co-founder Promoter</b></h6>
                          <p className='founder-p'>Introducing Manish Patel,They work together to create a comprehensive business plan that outlines the company’s mission, vision, goals, and strategies for achieving them,Co-founders often play a critical role in securing initial funding, whether through personal investment, loans, or attracting venture capital.</p>
                      </div>
                      </div>
                  </div>
                    </div>
                  </div>
              </div>
            </div>
        </section>
        <section className="professional_section py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-8 ">
                <div className="detail-box">
                  <h2>WE ARE THE BEST</h2>
                  <p className='p-0'>We are the best choice for Solar EPC website needs. Our customized solutions are tailored to the specific needs of solar EPC companies. We have a deep understanding of the industry and focus on lead generation and customer engagement. Our expertise in user experience and responsive design ensures that your website is accessible on any device. We offer ongoing support and maintenance to keep your website up-to-date with the latest industry trends and technologies. Choose us for the best Solar EPC website solutions.</p>
                  {/* <Link to="/contact">
                    Contact Us
                  </Link> */}
                </div>
              </div>
              <div className="col-md-4 text-center">
                <div className="img-box">
                <img src='./assets/images/best.png' alt=''/>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* end about section */}
        <section className="service_section py-5 about_we_work">
            <div className="container ">
                <div className="heading_container heading_center mb-5">
                    <h2>WHO WE ARE ?</h2>
                    <p>"Empowering Indore with sustainable solar solutions."</p>
                </div>
                <div className="row gy-5">
                    <div className="col-sm-12 col-md-12 col-lg-4 mb-5">
                    <div className="box ">
                        <div className="img-box">
                        <img src="./assets/images/s1.png" alt="" />
                        </div>
                        <div className="detail-box">
                        <h5>
                        SOLAR CONSULTATION
                        </h5>
                        <p>Solar consultation evaluates the feasibility of installing solar panels, recommends system size and type of panels, and provides guidance throughout installation. It assesses energy needs, solar resources, and potential savings, enabling informed decision-making.</p>
                        </div>
                    </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 mb-5">
                    <div className="box ">
                        <div className="img-box">
                        <img src="./assets/images/s2.png" alt="" />
                        </div>
                        <div className="detail-box">
                        <h5>
                        SOLAR INSTALLATION
                        </h5>
                        <p>Solar installation involves mounting solar panels and connecting them to the electrical system to generate clean energy. The process is customized to the property's energy needs and location, with the goal of reducing reliance on traditional energy sources.</p>
                        </div>
                    </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 mb-5">
                    <div className="box ">
                        <div className="img-box">
                        <img src="./assets/images/s3.png" alt="" />
                        </div>
                        <div className="detail-box">
                        <h5>
                        SOLAR MAINTENANCE
                        </h5>
                        <p>Solar maintenance is the regular cleaning and monitoring of a solar energy system to ensure peak performance and longevity. This involves inspecting wiring and connections, identifying and repairing issues, and maximizing energy output and cost savings.</p>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="btn-box">
                <Link target="_blank" rel="noopener noreferrer"  onClick={handleButtonClick}>
                          View More
                          </Link>
                </div>
            </div>
        </section>
        </>
        );
}
export default About;