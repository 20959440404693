import { useEffect, useState } from 'react';
import './Editprofile.css';
import { _userapiUrl } from '../../apiUrl';
import axios from 'axios';

function EditProfile() {

    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [error,setError] = useState(false);
    const [success,setSuccess] = useState();

    useEffect(()=>{
        axios.get(_userapiUrl+"fetch?email="+localStorage.getItem("email")).then((result)=>{
            setName(result.data[0].name);
            setMobile(result.data[0].mobile);
            setPassword(result.data[0].password);
            setEmail(result.data[0].email);
        }).catch((error)=>{
            
        });
    },[]);

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };

    const handleSubmit = (e)=>{
        e.preventDefault();
        if(name.length==0|| mobile.length<=9|| password.length == 0||email.length==0){
            setError(true);
        }
        else
        {
        const userDetails = {"condition_obj":{"email":email},"content_obj":{"name":name,"mobile":mobile,"password":password,"email":email}};
        axios.patch(_userapiUrl +"update",userDetails).then((result)=>{
            setSuccess("Submitted Successfully");
            setError(false);
            setTimeout(()=>{
                setSuccess("");
            },3000);
        }).catch((error)=>{
        })
        }
    }


    const [passwordType,setpasswordType] = useState('password');
    const [eyeChange,seteyeChange] = useState(<i class="fa fa-eye" aria-hidden="true"></i>);
    const handlePassword = ()=>{
        if (passwordType ==='text' ) {
            setpasswordType("password");
            seteyeChange(<i class="fa fa-eye" aria-hidden="true"></i>);
        }
        else
        {
            setpasswordType("text");
            seteyeChange(<i class="fa fa-eye-slash" aria-hidden="true"></i>);
        }
    }

    return(
        <>
        {/* contact section */}
        <section className="contact_section my-5 py-3">
            <div className="container">
                <div className="heading_container">
                    <h2>Edit Profile</h2>
                    <p>Please Enter your update details!!</p>
                </div>
                <div className="row">
                    <div className="col-md-12 mx-auto">
                        <div className="col-md-8">
                        <form>
                            <div>
                            <input type="text" placeholder="Full Name" value={name} onChange={e=>{setName(e.target.value)}}/>
                            {error && name.length <=0?<span className='text-danger'>Name is required</span>:""}
                            </div>
                            <div>
                            <input type="number" placeholder="Phone Number" value={mobile} onChange={e=>{setMobile(e.target.value)}} />
                            {error && mobile.length <= 9?<span className='text-danger'>Mobile number is required</span>:""}
                            </div>
                            <div>
                            <input type="email" placeholder="Email Address" value={email} onChange={e=>{setEmail(e.target.value)}} />
                            {error && !isValidEmail(email)?<span className='text-danger'>Email is required</span>:""}
                            </div>
                            <div className='password'>
                            <input type={passwordType}  placeholder="password" value={password} onChange={e=>{setPassword(e.target.value)}} />
                            <div className='eye_icon' onClick={handlePassword}>
                                {eyeChange}
                            </div>
                            {error && password.length <=0?<span className='text-danger'>Password is required</span>:""}
                            </div>
                            <div className="d-flex mb-1">
                            <button type='button'onClick={handleSubmit}>SUBMIT</button>
                            </div>
                            <b><span className='text-danger'>{error}</span></b>
                            <b><span className='text-success'>{success}</span></b>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
        );
}
export default EditProfile;