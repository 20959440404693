import { useEffect } from 'react';
import './sidebar.css';
import $ from 'jquery';

function Sidebar ({ setActiveItem }){
  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  useEffect(()=>{
    $(document).ready(function(){
      $(".sideclick").click(function(){
        $(".sidebar_menu").toggle();
      });
    });
  },[]);

  return (
    <>
    
        <div className="sidebar">
        <div className="d-flex justify-content-between align-items-center">
        <h2>Admin Panel</h2>
        <button className="sideclick d-block d-lg-none"><img src='./assets/images/text.png' alt=''/></button>
        </div>
        <ul className='sidebar_menu d-lg-block'>
            <li onClick={() => handleItemClick('Dashboard')}>Dashboard</li>
            <li onClick={() => handleItemClick('Users')}>ManageUsers</li>
            <li onClick={() => handleItemClick('projects')}>ManageProjects</li>
            <li onClick={() => handleItemClick('others')}>others</li>
            {/* Add more sidebar items as needed */}
        </ul>
        </div>
    </>
  );
};

export default Sidebar;
