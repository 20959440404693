import './Howwork.css';
import { Link } from 'react-router-dom';

function HowweWork() {
    const handleButtonClick = (e) => {
        const newTab = window.open('../assets/images/indore-solar-brochure.pdf', '_blank');
    
        if (newTab) {
            
            newTab.onload = () => {
                
                newTab.document.title = 'indore-solar-brochure.pdf';
                window.document.title='indore-solar-brochure.pdf';
            };
        } else {
          e.preventDefault();
            alert("Please allow popups for this website");
        }
      }
    return(
        <>
        {/* service section */}
        <section className="service_content">
        <section className="about_section py-5 product_section">
        <div className="overlay"></div>
            <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12">
                <div className="detail-box">
                    <h2>
                    POWERING SUSTAINABILITY: OUR COMMITMENT TO EXCEPTIONAL SOLAR SERVICES
                    </h2>
                    <p className='text-light'>At our core, we are driven by a passion for sustainability and a commitment to providing our customers with exceptional service. We work tirelessly to design and install reliable solar energy systems that help our clients reduce their carbon footprint and take control of their energy future.</p>
                    <Link to="/contact">Contact Us</Link>
                </div>
                </div>
                {/* <div className="col-lg-4 col-md-12">
                <div className="profile_pic">
                    <img src="./assets/images/about-img.jpg" alt=""/>
                </div>
                </div> */}
            </div>
            </div>
        </section>
        
        <section className="service_section service_box py-5">
            <div className="container ">
                <div className="heading_container heading_center mb-5">
                    <h2>How We Work ?</h2>
                </div>
                <div className="row gy-5">
                    <div className="col-sm-12 col-md-12 col-lg-6 mb-5">
                        <div className="box">
                            <div className="img-box">
                            <img src="./assets/images/s1.png" alt="" />
                            </div>
                            <div className="detail-box">
                            <h5>SHINE A LIGHT ON YOUR SOLAR POTENTIAL: SOLAR CONSULTATION SERVICES</h5>
                            <p>Solar consultation is a professional service that helps individuals and organizations determine the feasibility of installing a solar energy system on their property. The service includes an assessment of energy needs, solar resources, and cost-effectiveness, with recommendations on system size, type of solar panels, and potential savings. The service aims to provide clients with the information they need to make informed decisions and guidance throughout the installation process.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 mb-5">
                        <div className="box ">
                            <div className="img-box">
                            <img src="./assets/images/s2.png" alt="" />
                            </div>
                            <div className="detail-box">
                            <h5>STREAMLINING SOLAR INSTALLATIONS: THE SOLAR CONTRACT PROCESS</h5>
                            <p>A solar contract is a legal agreement between a solar company and a customer, involving a consultation and site assessment. After signing the contract, the solar installation process begins with obtaining permits, installing equipment, and connecting to the grid. Once a final inspection is conducted, the customer can enjoy the benefits of clean, renewable solar energy.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 mb-5">
                        <div className="box ">
                            <div className="img-box">
                            <img src="./assets/images/s2.png" alt="" />
                            </div>
                            <div className="detail-box">
                            <h5>EMPOWERING YOUR ENERGY FUTURE: PROFESSIONAL SOLAR INSTALLATION SERVICES</h5>
                            <p>Solar installation is the process of physically installing a solar energy system, including mounting the solar panels, connecting them to the electrical system, and ensuring proper configuration. A professional solar installer designs a system that is optimal for the location and energy needs, reducing reliance on traditional energy sources and harnessing clean energy from the sun.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 mb-5">
                        <div className="box ">
                            <div className="img-box">
                            <img src="./assets/images/s3.png" alt="" />
                            </div>
                            <div className="detail-box">
                            <h5>SUSTAINING SOLAR EFFICIENCY: COMPREHENSIVE SOLAR MAINTENANCE SERVICES</h5>
                            <p>Solar maintenance involves regular cleaning and monitoring of a solar energy system to ensure optimal performance and longevity. It includes inspecting wiring and connections, identifying issues, and providing repairs as needed. The goal is to maximize energy output and cost savings while prolonging the system's lifespan.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btn-box m-0">
                <Link target="_blank" rel="noopener noreferrer"  onClick={handleButtonClick}>
                          View More
                          </Link>
                </div>
            </div>
        </section>
        <section className="professional_section py-5">
              <div className="container">
                <div className="row">
                  
                  <div className="col-md-8 ">
                    <div className="detail-box">
                      <h2>THE POWER OF THE SUN: EMBRACE A BRIGHTER, CLEANER FUTURE WITH SOLAR ENERGY</h2>
                      <p className='p-0'>Embrace the limitless potential of the sun and make the switch to solar energy today to unlock a brighter, cleaner, and more sustainable future for yourself and the planet.</p>
                      <Link to="/contact">Get a Quote</Link>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="img-box">
                      <img src="./assets/images/best.png" alt=""/>
                    </div>
                  </div>
                </div>
              </div>
            </section>
    </section>
  {/* end service section */}
        </>
        );
}
export default HowweWork;