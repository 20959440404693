import './manageuser.css';
import { useEffect, useState } from 'react';
import { _userapiUrl } from '../../apiUrl';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
function ManageUser(){

    const [uDetails, setUDetails] = useState([]);
    const navigate = useNavigate();
    // fetch data
    useEffect(()=>{
        axios.get(_userapiUrl+"fetch?role=user").then((result)=>{
            setUDetails(result.data);
            // console.log(result.data);
        }).catch((error)=>{
            console.log(error);
        });
    },[uDetails]);


    const hendleDelete = (_id)=>{
            let deleteDetails = {"data":{"_id":_id}};
            if (window.confirm("Warning: Do you really want to delete the user?")){
                axios.delete(_userapiUrl + "delete", deleteDetails).then((response)=>{ 
                    // console.log(response.data);
                    navigate("/admin");
                }).catch((error)=>{
                    console.log(error.data);
                });
            }
            else
            {
                alert("User canceled delete action.");
            }
    }

    return(
    <>
        <div className="manage_user">
            <h2 className="heading">Manage Users</h2>
            <div className="table">
            <table className="table table-striped table-bordered  mt-0">
                            <thead className=''>
                                <tr>
                                    <th scope="col">RegID</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Mobile</th>
                                    <th scope="col">Message</th>
                                    <th scope="col">role</th>
                                    <th scope="col">Info</th>
                                    <th scope="col">Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    uDetails.map((row)=>(
                                        <tr>
                                            <td>{row._id}</td>
                                            <td>{row.name}</td>
                                            <td>{row.email}</td>
                                            <td>{row.mobile}</td>
                                            <td>{row.comment}</td>
                                            <td>{row.role}</td>
                                            <td>{row.info}</td>
                                            <td><button className='btn btn-danger' onClick={()=>{hendleDelete(row._id)}}>Delete</button></td>
                                            
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
            </div>
        </div>
    </>
    );
}
export default ManageUser;