import { Navigate } from "react-router-dom";

function Logout(){
    localStorage.clear();
    return(
        <>
            <Navigate to="/"></Navigate>
        </>
    );
}

export default Logout;