
import {useEffect } from 'react';
import {useNavigate} from 'react-router-dom';

function Auth(){

    const navigate = useNavigate();


    useEffect(()=>{
        var path = window.location.pathname;

        if(path === "/admin"){
            if (!localStorage.getItem("token") || localStorage.getItem("role") !== "admin") {
                navigate("/logout");
            }
        }
        else if(path === "/login"){
        
            if (!localStorage.getItem("token") || localStorage.getItem("role") !== "admin") {
                navigate("/login");
            }
            
        }
        else{
            if(localStorage.getItem("role") === "admin"){
                navigate("/admin");
            }
            else
            {
                navigate("/");
            }
        }
        },[]);

    return(
        <>
        <div></div>
        </>
    );
}

export default Auth;
