import './showproject.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { _projectapiUrl } from '../../apiUrl';
import { useParams } from 'react-router-dom';
function ShowProject() {
      const params = useParams();
      const [projectDetails,setprojectDetails] = useState();
  // fetch data
  useEffect(()=>{
    axios.get(_projectapiUrl+"fetch?_id="+params.Id).then((result)=>{
        setprojectDetails(result.data);
        console.log(result.data);
    }).catch((error)=>{
        console.log(error);
    });
},[params.Id]);

    return(
        <>
        <section className='show_project py-5'>
          <div className="container">
              <div className="row w-0">
                <div className="col-md-12">
                {
                projectDetails && projectDetails.map((row)=>(

                  <div className="row w-100" key={row._id}>
                    <div className="col-md-6">
                      <div className="about_pic">
                        <img src={`.././assets/uploads/${row.projectimg}`} alt=""/>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="detail-box">
                        <h2>{row.title}</h2>
                        <p className='p-0'>{row.description}</p>
                      </div>
                    </div>  
                  </div>
                ))
              }
                </div>
              </div>
	          </div>
        </section>
        </>
        );
}
export default ShowProject;