import './Home.css';
import { Link } from 'react-router-dom';
import ProjectSlider from '../ProjectSlider/projectSlider';
import {useRef } from 'react';
import React from 'react';


function Home() {
  
  const videoRef = useRef(null);

  // useEffect(()=>{
  //   videoRef.current.play().catch((error)=>{
  //     console.error('Autoplay error:', error);
  //   })
  // },[]);

  const handleButtonClick = (e) => {
    const newTab = window.open('../assets/images/indore-solar-brochure.pdf', '_blank');

    if (newTab) {
        
        newTab.onload = () => {
            
            newTab.document.title = 'indore-solar-brochure.pdf';
            window.document.title='indore-solar-brochure.pdf';
        };
    } else {
      e.preventDefault();
        alert("Please allow popups for this website");
    }
  }
    return(
        <>
    

        <section className="slider_section client_section">
                    <video ref={videoRef} id="background-video" loop muted autoPlay>
                        <source src="./assets/images/video1.mp4" type="video/mp4"/>
                    </video>
                    <div className="overlay"></div>
                    <div className="carousel-caption d-md-block">
                      <div className="detail-box">
                          <h1 className='text-uppercase'>Indore Solar Energy Solution pvt. ltd.</h1>
                          <p>We are the best choice for Solar EPC website needs. Our customized solutions are tailored to the specific needs of solar EPC companies. We have a deep understanding of the industry and focus on lead generation and customer engagement.</p>
                          <Link to="/contact">
                          Contact Us
                          </Link>
                      </div>
                    </div>
                  
              </section>
              {/* end slider section*/}
              <section className="service_section py-5">
                  <div className="container">
                      <div className="heading_container heading_center">
                          <h2>WHAT WE OFFER ?</h2>
                      </div>
                      <div>
                      <div className="row gy-5">
                          <div className="col-sm-12 col-md-12 col-lg-4 mb-5">
                          <div className="box ">
                              <div className="img-box">
                              <img src="./assets/images/s1.png" alt="" />
                              </div>
                              <div className="detail-box">
                              <h5>
                              Commercial Rooftop Solar Installation
                              </h5>
                              <p>Commercial rooftop solar installations are a popular way for businesses to save money, reduce their carbon footprint, and demonstrate their commitment to sustainability. By generating their own clean energy and potentially selling excess energy back to the grid, businesses can benefit financially and attract eco-conscious customers.</p>
                              </div>
                          </div>
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-4 mb-5">
                          <div className="box ">
                              <div className="img-box">
                              <img src="./assets/images/s2.png" alt="" />
                              </div>
                              <div className="detail-box">
                              <h5>
                              Residential Rooftop Installation
                              </h5>
                              <p>Residential rooftop solar installations offer homeowners a way to save money, generate clean energy, and reduce their reliance on fossil fuels. With financing options like leases and loans available, it's more affordable than ever before. By investing in solar panels on their rooftops, homeowners can contribute to a more sustainable future while benefiting financially.</p>
                              </div>
                          </div>
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-4 mb-5">
                          <div className="box ">
                              <div className="img-box">
                              <img src="./assets/images/s3.png" alt="" />
                              </div>
                              <div className="detail-box">
                              <h5>
                              Ground Mounted Solar Installation
                              </h5>
                              <p>Ground mounted solar installations offer flexibility and efficiency compared to rooftop installations. By installing solar panels on a sturdy frame on the ground, homeowners and businesses can optimize their energy production, have easier maintenance, and access for cleaning and repairs. This is especially useful in areas with high winds, heavy snow loads, or limited roof space.</p>
                              </div>
                          </div>
                          </div>
                      </div>
                      </div>
                      <div className="btn-box mt-5">
                      <Link target="_blank" rel="noopener noreferrer"  onClick={handleButtonClick}>
                          View More
                          </Link>
                      </div>
                  </div>
              </section>

          {/* professional section */}
        
          <section className="professional_section py-5">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div className="img-box">
                    <img src="./assets/images/enjoy.png" alt=""/>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6 ">
                  <div className="detail-box">
                    <h2>YOU WILL ENJOY</h2>
                    <h6><i className="fa fa-check" aria-hidden="true"></i> <b>COST SAVINGS BY GENERATING OWN POWER</b></h6>
                    <h6><i className="fa fa-check" aria-hidden="true"></i> <b>INCREASED PROPERTY VALUE </b></h6>
                    <p>Installing a solar energy system can increase the valueof a homeor property, making it a smart invesment.</p>
                    <h6><i className="fa fa-check" aria-hidden="true"></i> <b>ENVIRONMENTAL BENEFITS</b></h6>
                    <p>Solar energy is a clean.</p>
                    <h6><i className="fa fa-check" aria-hidden="true"></i> <b>GOVERNMENT INCENTIVE </b></h6>
                    <p>In form of GOVT. subsidy</p>
                    <h6><i className="fa fa-check" aria-hidden="true"></i> <b>ENERGY INDEPENDENCE</b></h6>
                    <p>Generate your own power.</p>
                    <h6><i className="fa fa-check" aria-hidden="true"></i> <b>LOW MAINTENANCE:- </b></h6>
                    <p>Solar panels require minimal maintenance</p>
                    <Link to="/contact">Get Quote!!</Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        
          {/* end professional section */}
          <section className="about_section py-3 my-5">
                  <div className="container">
                  <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="detail-box text-center">
                          <h2 className='text-uppercase'>Meet Our Founding Member</h2>
                          
                          <p>Meet the members of our renewable energy community, driving change towards a sustainable future.</p>
                      <div className="founder_pic">
                          <img src="./assets/images/mohar.png" alt=""/>
                      </div>
  
                          <h5><b style={{"color":"#fcc72d"}}>Mohar Singh Gurjar</b></h5>
                          <h6><b>Founder Promoter</b></h6>
                          <p className='founder_para'>Introducing Mohar Singh, a passionate renewable energy expert with over 8 years of experience in sales and solar installation. Their expertise has been instrumental in the Laisoning and installation of solar panels for over 1000 families, helping countless homeowners and businesses transition to clean energy and reduce their carbon footprint. Mohar Singh's commitment to exceptional customer service and attention to detail has earned them a reputation for delivering high-quality, customized solutions that meet the unique needs of each client. With a deep understanding of the renewable energy landscape and a dedication to driving change towards a more sustainable future, Mohar Singh is a valuable addition to our team of renewable energy specialists.</p>
                          <Link to="/projects">See Projects</Link>
                      </div>
                      </div>
                  </div>
                  </div>
              </section>
            <section className="professional_section py-5">
              <div className="container">
                <div className="row justify-content-center">
                  {/* <div className="col-sm-12 col-md-12 col-lg-4">
                    <div className="img-box">
                      <img src="./assets/images/" alt=""/>
                    </div>
                  </div> */}
                  <div className="col-sm-12 col-md-12 col-lg-10">
                    <div className="detail-box text-center">
                      <h2>JOIN THE MOVEMENT: POWER YOUR HOME WITH SOLAR ENERGY</h2>
                      <p className='px-5'>Switching to solar power is not just about saving money, it's about saving the planet. Join the movement towards a sustainable future and power your home with the energy of the sun.</p>
                      <Link to="/contact">Contact Us</Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          <ProjectSlider/>
          
        </>
        );
}
export default Home;