
import React from 'react';
import { useState } from 'react';
import './mainpopup.css'; 
import Message from './messagepopup/message';
import ContactPop from './contactpopup/contact';
const MainPopup = ({ isOpen}) => {
  const [isIcon, setIsicon] = useState(<i class="fa fa-whatsapp" aria-hidden="true"></i>);
  const [isMessageOpen, setMessageOpen] = useState(false);
  const [isContactOpen, setContactOpen] = useState(false);
  const [isContactIcon, setIsContactIcon] = useState(<i class="fa fa-comments-o" aria-hidden="true"></i>);
  

  if (!isOpen) return null;

  
  const handlePopupOpen = () => {
    setMessageOpen(true);
    setIsicon(<i class="fa fa-times" aria-hidden="true"></i>);
    if (isMessageOpen === true) {
      setMessageOpen(false);
      setIsicon(<i class="fa fa-whatsapp" aria-hidden="true"></i>)
    }
  };
  const handleContactPopupOpen = () => {
    setContactOpen(true);
    setIsContactIcon(<i class="fa fa-times" aria-hidden="true"></i>);
    if (isContactOpen === true) {
      setContactOpen(false);
      setIsContactIcon(<i class="fa fa-comments-o" aria-hidden="true"></i>)
    }
  };
  return (
    <>
    
    <div className="popup-content">
        <ContactPop contactOpen = {isContactOpen} />
        <button className="btn btn-primary"  onClick={handleContactPopupOpen}>{isContactIcon}</button>
      </div>
    <div className="popup-content">
        <Message messageOpen = {isMessageOpen} />
        <button className="btn btn-primary" onClick={handlePopupOpen}>{isIcon}</button>
      </div>

    
    </>
      
    
  );
};

export default MainPopup;
