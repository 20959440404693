import { useState } from 'react';
import './login.css';
import axios from 'axios';
import { _userapiUrl } from '../../apiUrl';
import { useNavigate } from 'react-router-dom';
function Login(){
  const navigate = useNavigate();
  const [email,setEmail] = useState('');
  const [password,setPassword] = useState('');
  const [error,setError] = useState(false);
  const [success,setSuccess] = useState();

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const hendleSubmit = (e)=>{
    e.preventDefault();
    if (!email || !password || email.length === 0|| password.length===0) {
      setError(true);
    }
    else
    {
        let userDetails = {"email":email,"password":password,"role":"admin"};
        axios.post(_userapiUrl+"login",userDetails).then((result)=>{
        if (result.data.token !== "error") {
          let data = result.data.userDetails;
          localStorage.setItem("token",result.data.token);
          localStorage.setItem("email",data.email);
          localStorage.setItem("name",data.name);
          // localStorage.setItem("password",data.password);
          localStorage.setItem("role",data.role);
          setSuccess("login Successfully...");
          (data.role === "admin")?navigate("/admin"):navigate("/");
          setTimeout(()=>{
            setSuccess();
            },5000);
        }
      
      }).catch((error)=>{
        if (error.response.data.token === "error") {
          setError("Invalid user login failed , please try again....");
          setEmail("");
          setPassword("");
        }
        setTimeout(()=>{
          setError();
          },5000);
      });
    }
  }

  
  const [passwordType,setpasswordType] = useState('password');
  const [eyeChange,seteyeChange] = useState(<i className="fa fa-eye" aria-hidden="true"></i>);
  const handlePassword = ()=>{
      if (passwordType ==='text' ) {
          setpasswordType("password");
          seteyeChange(<i className="fa fa-eye" aria-hidden="true"></i>);
      }
      else
      {
          setpasswordType("text");
          seteyeChange(<i className="fa fa-eye-slash" aria-hidden="true"></i>);
      }
  }

  return(
    <>
      <div className="login_form">
        <div className="container">
          <div className="card">
            <h2>Login</h2>
            <form>
              <div>
                <input type="email" value={email} placeholder="Email address" onChange={(e)=>setEmail(e.target.value)} required />
                {error && !isValidEmail(email)?<span className='text-danger'>Email is required</span>:""}
              </div>
              <div className='password'>
                <input type={passwordType}  placeholder="password" value={password} onChange={(e)=>setPassword(e.target.value)} />
                <div className='eye_icon' onClick={handlePassword}>
                    {eyeChange}
                </div>
                {error &&!password? <span className='text-danger'>Password is required</span>:""}

              </div>
              <button type="button" className="mb-2" onClick={hendleSubmit}>Login</button>
              {/* <b><span className='text-danger'>{error}</span></b> */}
              <b><span className='text-success'>{success}</span></b>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
