
import React from 'react';
import './message.css'; 
import { Link } from 'react-router-dom';

const Message = ({ messageOpen}) => {

  if (!messageOpen) return null;

  return (
    <>
    <div className="message_content">
    <div className="popup-content">
      <p>“We're here to help. If you have any questions or need assistance, please don't hesitate to contact us.”</p>
      <Link to="tel:-+918982304701">+918982304701</Link>
      </div>
    </div>
    </>
      
    
  );
};

export default Message;
