import './dashboard.css';
import ManageUser from '../ManageUser/manageuser';
import DashboardContent from '../Dashboardcontent/DashboardContent';
import ManageProject from '../ManageProject/manageproject';
function Dashboard({ activeItem }){
  return (
    <div className="dashboard">
      {activeItem === 'Dashboard' && <DashboardContent/>}
      {activeItem === 'Users' && <ManageUser/>}
      {activeItem === 'projects' && <ManageProject/>}
      {activeItem === 'others' && <h1>Products Content</h1>}
      {/* Add more dashboard content based on the active item */}
    </div>
  );
};

export default Dashboard;
