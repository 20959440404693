import './Admin.css';
import {useState} from 'react';

import Dashboard from '../Dashboard/dashboard';
import Sidebar from '../Sidebar/sidebar';

function Admin(){
    const [activeItem, setActiveItem] = useState('Dashboard');

    return(

        <>
        <section className="admin_panel">
            <div className="container-fluid p-0">
                <div className="admin row g-0">
                    <div className="col-lg-3 col-md-12">
                        <Sidebar setActiveItem={setActiveItem} />
                    </div>
                    <div className="col-lg-9 col-md-12">
                        <Dashboard activeItem={activeItem} />
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default Admin;