import { useEffect } from 'react';
import './projectSlider.css';
import { Link } from 'react-router-dom';
function ProjectSlider(){

    useEffect(()=>{
      window.jQuery(document).ready(function($) {
        $(".owl-carousel").owlCarousel({
          loop: true,
          margin: 10,
          nav: true,
          dots: true,
          items:4,
          navText: [
              '<i class="fa fa-long-arrow-left" aria-hidden="true"></i>',
              '<i class="fa fa-long-arrow-right" aria-hidden="true"></i>'
          ],
          autoplay: true,
          autoplayHoverPause: false,
          responsive: {
              0: {
                  items: 1
              },
              768: {
                  items: 2
              },
              1000: {
                  items: 3
              }
          }
      });
      })
        
    },[])
    return(
        <>
    <section className="client_section py-5">
      <div className="container-fluid">
        <div className="heading_container heading_center heading_slider">
          <h2>Our Projects</h2>
          <p>Switching to solar power is not just about saving money, it's about saving the planet. Join the movement towards a sustainable future and power your home with the energy of the sun.</p>
        </div>

        <div className="carousel-wrap ">
          <div className="owl-carousel">
            <div className="item project_img">
                    <Link to="/projects"><img src="./assets/images/project1.jpeg" alt=""/></Link>
            </div>
            <div className="item project_img">
                    <Link to="/projects"><img src="./assets/images/project2.jpeg" alt=""/></Link>
            </div>
            <div className="item project_img">
                    <Link to="/projects"><img src="./assets/images/project3.jpeg" alt=""/></Link>
            </div>
            <div className="item project_img">
                    <Link to="/projects"><img src="./assets/images/project4.jpeg" alt=""/></Link>
            </div>
            <div className="item project_img">
                    <Link to="/projects"><img src="./assets/images/project5.jpeg" alt=""/></Link>
            </div>
          </div>
        </div>
      </div>
    </section>
        </>
    );
}

export default ProjectSlider;