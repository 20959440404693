import { useState } from 'react';
import { Link } from 'react-router-dom';
import './Contact.css';
import { _userapiUrl } from '../../apiUrl';
import axios from 'axios';

function Contact() {


    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [error,setError] = useState(false);
    const [success,setSuccess] = useState();
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };

    const handleSubmit = (e)=>{
        e.preventDefault();
        if (name.length==0 || mobile.length <= 9|| true == !isValidEmail(email) ||message == 0 ) {
            setError(true);
        }
       else
       {
        const userDetails = {"name":name,"mobile":mobile,"email":email,"comment":message};
        // console.log(userDetails)
        axios.post(_userapiUrl+"save",userDetails).then((result)=>{
            setName("");
            setEmail("");
            setMessage("");
            setMobile("");
            setSuccess("Submitted Successfully");
            setError(false);
            setTimeout(()=>{
                setSuccess("");
            },3000);
        }).catch((error)=>{

        })
       }
        
    }

    return(
        <>
        {/* contact section */}
        <section className="contact_section my-5 py-3">
            <div className="container">
                <div className="heading_container">
                    <h2>Contact Us</h2>
                    <p>Please Share your Details our team will connect soon !!</p>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                    <form className='mb-3'>
                        <div>
                        <input type="text" placeholder="Full Name" value={name} onChange={e=>{setName(e.target.value)}}/>
                        {error && name.length <= 0?<span className='text-danger'>Name is required</span>:""}
                        </div>
                        <div>
                        <input type="number" placeholder="Phone Number" value={mobile} onChange={e=>{setMobile(e.target.value)}} />
                        {error && mobile.length <= 9?<span className='text-danger'>Mobile number is required</span>:""}
                        </div>
                        <div>
                        <input type="email" placeholder="Email Address" value={email} onChange={e=>{setEmail(e.target.value)}} required/>
                        {error && !isValidEmail(email)?<span className='text-danger'>Email is required</span>:""}
                        </div>
                        <div>
                        <textarea type="text" className="message-box" placeholder="Comment" value={message} onChange={e=>{setMessage(e.target.value)}}></textarea>
                        {error && message.length <= 0?<span className='text-danger'>Comment is required</span>:""}
                        </div>
                        <div className="d-flex mb-1">
                        <button type='button'onClick={handleSubmit}>SEND</button>
                        </div>
                        <b><span className='text-success'>{success}</span></b>
                    </form>
                    </div>
                    <div className="col-lg-6 col-md-12">
                    <div className="map_container">
                        <div className="map">
                        <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4808.386321694564!2d75.9110990235813!3d22.71507738504426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962e2db1da52b47%3A0xe0bea81bc24a2da4!2s64%2C%20Sri%20Mangal%20Nagar%2C%20Indore%2C%20Madhya%20Pradesh%20452016!5e0!3m2!1sen!2sin!4v1705348026074!5m2!1sen!2sin" width={600} height={450} allowFullScreen={true} referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>

  {/* end contact section */}
  <section className="get_section py-5">
                    <div className="container">
                        <h4 className='text-center'>
                        Get In Touch
                        </h4>
                        <div className="row">
                        <div className="col-lg-12 mx-auto">
                            <div className="info_items">
                            <div className="row">
                                <div className="col-md-3">
                                <Link to="/">
                                    <div className="item ">
                                    <div className="img-box ">
                                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                                    </div>
                                    <br/>
                                    <h6>ADDRESS</h6>
                                    <p>
                                    64, Shri Managal Nagar<br/> Bicholi Hapsi Road, Indore
                                    </p>
                                    </div>
                                </Link>
                                </div>
                                <div className="col-md-3">
                                <Link to="tel:+918982304701">
                                    <div className="item ">
                                    <div className="img-box ">
                                        <i className="fa fa-phone" aria-hidden="true"></i>
                                    </div>
                                    <br/>
                                    <h6>PHONE NUMBER</h6>
                                    <p>
                                    +918982304701
                                    </p>
                                    </div>
                                </Link>
                                </div>
                                <div className="col-md-3">
                                <Link to="/">
                                    <div className="item ">
                                    <div className="img-box">
                                    <i className="fa fa-clock-o" aria-hidden="true"></i>
                                    </div>
                                    <br/>
                                    <h6>OPENING TIME</h6>
                                    <p>Mon-Sat: 08.00 -18.00</p>
                                    </div>
                                </Link>
                                </div>
                                <div className="col-md-3">
                                <Link to="mailto:info@indoresolar.in">
                                    <div className="item ">
                                    <div className="img-box">
                                        <i className="fa fa-envelope" aria-hidden="true"></i>
                                    </div>
                                    <br/>
                                    <h6>EMAIL ADDRESS</h6>
                                    <p>Info@indoresolar.in</p>
                                    </div>
                                </Link>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>
        </>
        );
}
export default Contact;